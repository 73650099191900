<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>评价规范管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="已有规范" icon="ios-paper">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="ruleList"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="添加规范" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form ref="addRule" :model="newRule" :rules="ruleValidate" :label-width="250">
                <FormItem label="名称：" prop="name" style="width:550px">
                  <Input v-model="newRule.name" />
                </FormItem>
                <FormItem label="发布时间：" prop="pubDate" style="width:500px">
                  <input type="date" v-model="newRule.pubDate" placeholder/>
                </FormItem>
                <FormItem label="执行时间：" prop="exeDate" style="width:500px">
                  <input type="date" v-model="newRule.exeDate" placeholder/>
                </FormItem>
                <FormItem label="资料类别：" prop="catagory" style="width:438px">
                  <Select v-model="newRule.catagory"><Option v-for="ele in option.catagoryChoices" :key="ele.value" :value="ele.value">{{ele.label}}</Option></Select>
                </FormItem>
                <FormItem label="上传文件：" prop="pdfFile">
                  <div>
                    <Upload
                      ref="upload"
                      :before-upload="handleUpload"
                      :on-success="handleSuccess"
                      action
                    >
                      <Button class="button-wrapper" icon="ios-cloud-upload-outline">请选择上传文件</Button>
                    </Upload>
                    <div v-if="file !== null" class="file-space-wrapper">
                      <div class="file-space">
                        <div class="file-icon-wrapper">
                          <Icon type="ios-document-outline" :size="50" />
                        </div>
                        <div class="fp-inner">
                          <p>{{ file.name }}</p>
                          <p>{{file.size/1000+"K"}}</p>
                        </div>
                        <div class="del-icon-wrapper">
                          <Button
                            class="del-button"
                            @click="handleRemove"
                            type="text"
                            icon="ios-trash"
                            size="large"
                          ></Button>
                        </div>
                      </div>
                      <div class="button-wrapper"></div>
                    </div>
                  </div>
                </FormItem>
                <FormItem>
                  <Button
                    @click="handleSubmit('addRule')"
                    :loading="loadingStatus"
                    size="large"
                  >{{loadingStatus ? 'Uploading' : '确认提交' }}</Button>
                  <Button @click="handleReset('addRule')" size="large" style="margin-left: 8px">重置</Button>
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>规范信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form ref="alterRule" :model="currentRule" :rules="ruleValidateAlter" :label-width="250">
                <FormItem label="名称：" prop="name" style="width:550px">
                  <Input v-model="currentRule.name" />
                </FormItem>
                <FormItem label="发布时间：" prop="pubDate" style="width:500px">
                  <input type="date" v-model="currentRule.pubDate" placeholder/>
                </FormItem>
                <FormItem label="执行时间：" prop="exeDate" style="width:500px">
                  <input type="date" v-model="currentRule.exeDate" placeholder/>
                </FormItem>
                <FormItem label="资料类别：" prop="catagory" style="width:438px">
                  <Select v-model="currentRule.catagory"><Option v-for="ele in option.catagoryChoices" :key="ele.value" :value="ele.value">{{ele.label}}</Option></Select>
                </FormItem>
                <FormItem label="上传文件：" prop="pdfFile">
                  <div>
                    <Upload
                      ref="upload"
                      :before-upload="handleUploadAlter"
                      :on-success="handleSuccess"
                      action
                    >
                      <Button class="button-wrapper" icon="ios-cloud-upload-outline">请选择上传文件</Button>
                    </Upload>
                    <div v-if="fileAlter !== null" class="file-space-wrapper">
                      <div class="file-space">
                        <div class="file-icon-wrapper">
                          <Icon type="ios-document-outline" :size="50" />
                        </div>
                        <div class="fp-inner">
                          <p>{{ fileAlter.name }}</p>
                          <p>{{fileAlter.size/1000+"K"}}</p>
                        </div>
                        <div class="del-icon-wrapper">
                          <Button
                            class="del-button"
                            @click="handleRemove"
                            type="text"
                            icon="ios-trash"
                            size="large"
                          ></Button>
                        </div>
                      </div>
                      <div class="button-wrapper"></div>
                    </div>
                  </div>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterRule')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function ruleFac() {
  return {
    id:0,
    name: "",
    catagory: "",
    pubDate: null,
    exeDate: null,
    faType: '',
    stat:"",
    pdfFile: ""
  };
}

export default {
  name: "ruleManage",
  components: {
    queryTable,
  },
  props: {},
  data() {
    return {
      askData: "ruleManage",
      update_count: 0,
      columnsFormatData: [
        {
          title: "规范名称",
          key: "name",
          align: "center",
        },

        {
          title: "类别",
          key: "catagory",
          align: "center",
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      atimated: false,
      newRule: ruleFac(),
      currentRule: ruleFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入资料名称！",
            trigger: "blur"
          }
        ],
        catagory: [
          {
            required: true,
            type: "string",
            message: "请选择资料类别！",
            trigger: "blur"
          }
        ],
        pdfFile: [
          {
            type: "string",
            required: true
          }
        ]
      },
      ruleValidateAlter: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入资料名称！",
            trigger: "blur"
          }
        ],
        catagory: [
          {
            required: true,
            type: "string",
            message: "请选择资料类别！",
            trigger: "blur"
          }
        ],
       
      },
      file: null,
      fileAlter: null,
      option:{
          catagoryChoices: [
        {
          value: "标准",
          label: "标准",
        },
        {
          value: "法规",
          label: "法规",
        },
      ],
      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.get_option_data();
  },
  methods: {
    get_option_data() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "ruleManage",
        })
        .then((response) => {
          this.option = response.data.res_record;
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.file = null;
      this.fileAlter = null;
    },
    handleRemove() {
      this.file = null;
      this.fileAlter = null;
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("file", this.file);
          form.append("userToken", userToken);
          form.append("askData", "addRule");
          form.append("newRule", JSON.stringify(this.newRule));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/rule_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("规范添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleSuccess() {},
    handleUpload(file) {
      if (file.name.split(".").pop() !== "pdf"&&file.name.split(".").pop() !== "doc"&&file.name.split(".").pop() !== "docx") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!"
        });
      } else if (file.size > 20480000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于20MB!"
        });
      } else {
        this.file = file;
        this.newRule.pdfFile = file.name;
      }
      return false;
    },
    handleUploadAlter(file) {
      if (file.name.split(".").pop() !== "pdf"&&file.name.split(".").pop() !== "doc"&&file.name.split(".").pop() !== "docx") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf或.doc或.docx”的文件!"
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!"
        });
      } else {
        this.fileAlter = file;
        this.currentRule.pdfFile = file.name;
      }
      return false;
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("file", this.fileAlter);
          form.append("userToken", userToken);
          form.append("askData", "alterRule");
          form.append("currentRule", JSON.stringify(this.currentRule));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/rule_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("规范修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该规范信息：",
        content:
          "<p>" +
          "规范名称：" +
          value.name +
          "</p>" +
          "<p>" +
          "类别：" +
          value.catagory +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/rule_alter", {
          userToken: userToken,
          askData: "delRule",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentRule = ruleFac();
        this.currentRule.id = value.id;
        this.currentRule.name = value.name;
        this.currentRule.catagory = value.catagory;
        this.currentRule.pubDate = value.pubdate;
        this.currentRule.exeDate = value.exedate;
        this.currentRule.faType = value.fa_type;
        this.currentRule.stat = value.stat;
        this.currentRule.pdfFile = null;
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>